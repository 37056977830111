import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Work from '../components/Work';
import { Col, Row } from 'react-bootstrap';
import Header from '../components/Header';
import { initialProjects } from '../initialProjects';
import Category from '../components/Category';
import { t } from 'i18next';
import CategoryLight from '../components/CategoryLight';
import CategoryPipe from '../components/CategoryPipe';

export default function HomeScreen() {
  const { t } = useTranslation();
  const [mydata, setdata] = useState(initialProjects);

  function reveal() {
    var reveals = document.querySelectorAll('.reveal');

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add('active');
      } else {
        reveals[i].classList.remove('active');
      }
    }
  }

  window.addEventListener('scroll', reveal);

  return (
    <div>
      <Header />
      <Work />
      <div className="container-fluid d-flex  container-section-first ">
        <div className="container my-4 ">
          <div className="col-service ">
            <h1 className="h1-title">{t('powerTools')}</h1>
            <div className="powerTools">
              <h2 className="h2-title">Power Tools</h2>
            </div>
            <Row>
              {mydata.length === 0 && console.log('No Categories found kaka')}

              {mydata.oldProjects.slice(0, 16).map((category) => (
                <Col
                  key={category.created_at}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="mb-3 reveal"
                >
                  <Category category={category}></Category>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex  container-section-first ">
        <div className="container my-4 ">
          <div className="col-service ">
            {/* <h1 className="h1-title">{t('powerTools')}</h1> */}
            <div className="powerTools">
              <h2 className="h2-title">Lighting</h2>
            </div>
            <Row>
              {mydata.length === 0 && console.log('No Categories found kaka')}

              {mydata.lighProducts.slice(0, 16).map((category) => (
                <Col
                  key={category.created_at}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="mb-3 reveal"
                >
                  <CategoryLight category={category}></CategoryLight>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex  container-section-first ">
        <div className="container my-4 ">
          <div className="col-service ">
            {/* <h1 className="h1-title">{t('powerTools')}</h1> */}
            <div className="powerTools">
              <h2 className="h2-title">Pipes</h2>
            </div>
            <Row>
              {mydata.length === 0 && console.log('No Categories found kaka')}

              {mydata.pipeProducts.slice(0, 16).map((category) => (
                <Col
                  key={category.created_at}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="mb-3 reveal"
                >
                  <CategoryPipe category={category}></CategoryPipe>
                </Col>
              ))}
            </Row>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-image"></div>
        <div className="bg-image d-flex">
          <div className="transparent w-100">
            <div className="container">
              <div className="row">
                <div className="col-md-6 ">
                  <img
                    className="img-section-bottom"
                    src="./images/con1.png"
                    alt="./images/con1.png"
                  />
                </div>
                <div className="col-md-6 section-info-bottom reveal">
                  <h1 className="bottom-text">
                    <strong>{t('grow_statement')}</strong>
                  </h1>
                </div>
                {/* <div className="col-sm-5 col-md-2"></div>
                <div className="col-sm-5 col-md-8">
                  <h3>
                    <strong>{t('grow-section')}</strong>
                  </h3>

                  <p className="text-justify">{t('grow_statement')}</p>
                </div>
                <div className="col-sm-5 col-md-2"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid d-flex container-section">
        <div className="container">
          <div className="row d-flex">
            <div className="col-md-4 section-info">
              <img
                className="img-section"
                src="./images/ll2.png"
                alt="./images/ll2.png"
              ></img>
            </div>
            <div className="col-md-4">
              <img
                className="img-section"
                src="./images/ll3.png"
                alt="./images/ll3.png"
              ></img>
            </div>
            <div className="col-md-4">
              <img
                className="img-section"
                src="./images/ll1.png"
                alt="./images/ll1.png"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid d-flex container-section-bottom reveal">
        <div className="container-fluid">
          <div className="row d-flex">
            <div className="col-md-3 section-info">
              <img
                className="img-section"
                src="./images/power.png"
                alt="./images/power.png"
              ></img>
            </div>
            <div className="col-md-3">
              <img
                className="img-section"
                src="./images/paint.png"
                alt="./images/paint.png"
              ></img>
            </div>
            <div className="col-md-3">
              <img
                className="img-section"
                src="./images/dec.png"
                alt="./images/dec.png"
              ></img>
            </div>
            <div className="col-md-3">
              <img
                className="img-section"
                src="./images/elec.png"
                alt="./images/elec.png"
              ></img>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid d-flex container-section-bottom reveal">
        <div className="">
          <div className="row d-flex">
            <div className="col-3 ">
              <img
                className="img-section"
                src="./images/power.png"
                alt="./images/power.png"
              />
            </div>
            <div className="col-3 col-md-3 ">
              <img
                className="img-section"
                src="./images/paint.png"
                alt="./images/paint.png"
              />
            </div>
            <div className="col-3">
              <img
                className="img-section"
                src="./images/dec.png"
                alt="./images/dec.png"
              />
            </div>
            <div className="col-3">
              <img
                className="img-section"
                src="./images/elec.png"
                alt="./images/elec.png"
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
