import React, { useEffect, useState } from 'react';
import useInterval from 'react-use/lib/useInterval';

export default function TextAnimation() {
  const autoArray = 'Nsur'.split('');
  //   const systemArray = 'Syst'.split('');
  const [items, setItems] = useState(autoArray);
  const [count, setCount] = useState(0);
  const [play, setPlay] = useState(false);

  useInterval(
    () => {
      setItems(autoArray);
      setCount(count + 1);
      if (count === 1) {
        setCount(0);
        // setItems(systemArray);
      }
    },
    play ? 6000 : null
  );
  useEffect(() => {
    const timer = setTimeout(() => {
      //   setItems(systemArray);
      setPlay(true);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="z-glossy">
      <span className="Wrapper">
        {items.map((item, index) => (
          <span key={index}>{item}</span>
        ))}
      </span>
      code
    </div>
  );
}
