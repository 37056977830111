import React from 'react';
import { useTranslation } from 'react-i18next';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextAnimation from '../components/TextAnimation';
import Mailer from '../components/Mailer';

export default function ContactUs() {
  const { t } = useTranslation();
  const bannerImage = '../images/contact.jpg';
  const bannerMore = '../images/map.png';

  return (
    <>
      <div className="imgHeaderService d-flex justify-content-center align-items-center h-100">
        <img
          className="d-flex w-100 bg-image"
          src={bannerImage}
          alt={bannerImage}
        ></img>

        <div>
          <div className="content">
            <h6>Nsur AL Basrah</h6>
            <strong>{t('about_Us')}</strong>
          </div>
        </div>
      </div>

      <div className="container d-flex my-4">
        <div>
          <Row>
            <h4>
              <strong>{t('Contact_us')}</strong>
            </h4>
          </Row>
          <Row>
          <Col md={12}>
                       <div className="d-flex my-2 ">
              <i className="fa fa-location-arrow colorInfo p-2"></i>
              Iraq - erbil - 100m road - Opposite Family Mall
            </div>
            <div className="d-flex  my-2">
              <i className="fa fa-envelope colorInfo p-2 center"></i>
              info@nsuralbasrah.com
            </div>
            <div className="d-flex p-1 my-1 ">
              <i className="fa fa-phone colorInfo p-1 fa-lg"></i>
              00964 771 452 5656
            </div>
            <div className="d-flex p-1 my-1 ">
              <i className="fa fa-phone colorInfo p-1 fa-lg"></i>
              00964 750 752 5652
            </div>
          </Col>
            {/* <Col sm={6}>
              <Mailer></Mailer>
            </Col>
            <Col
              sm={6}
              className="d-flex justify-content-center align-center align-items-center agency "
            >
              <h2 className="text-color1">{t('contact_statement')}</h2>
            </Col> */}

            <div className="image-group"></div>
          </Row>
        </div>
      </div>
      <div className=""></div>
      <div className=" d-flex">
        <div className="transparent w-100">
          <div className="container">
            <div className="row">
              <div className="">
                <img
                  className="img-contact w-100"
                  src={bannerMore}
                  alt={bannerMore}
                ></img>
              </div>
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
