export const initialProjects = {
  oldProjects: [
    {
      _id: '1',
      projectName: 'سیستەمی ئاڵوگۆڕی دراو',
      // created_at: '2021-10-16T21:51:35Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Training-Tracker',
      // homepage: 'https://training-tracker-uk.herokuapp.com/',
      // description:
      //   'In this app you are able to view create and track daily workouts using MongoDb Atlas and deployed to Heroku. You also be able to log multiple exercises in a workout on a given day.',
      project_img: '../images/products/g1.jpg',
      languages: {
        JavaScript: 16404,
        CSS: 7166,
        HTML: 5742,
      },
    },
    {
      _id: '2',
      projectName: 'مێنو ساردەمەنی هەولێر',
      // created_at: '2021-09-02T11:02:31Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/E-Commerce-BackEnd',
      // homepage: 'https://MehdiMahmud79.github.io/E-Commerce-BackEnd/',
      // description:
      //   'An app to interact with a MySQL database Using Sequelize in ExpressJs and API.',
      project_img: '../images/products/g2.jpg',
      languages: {
        JavaScript: 17481,
        Shell: 41,
      },
    },
    {
      _id: '3',
      projectName: 'ماڵپەڕی کۆمپانیای سیپاک',
      // created_at: '2021-08-31T11:06:31Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Employee-Tracker',
      // homepage: 'https://MehdiMahmud79.github.io/Employee-Tracker/',
      // description:
      //   'Using the SQL databse to creat a command-line application interfaces that allow non-developers to easily view and interact with information stored in databases.',
      project_img: '../images/products/g3.jpg',
      languages: {
        JavaScript: 11283,
      },
    },
    {
      _id: '4',
      projectName: 'وێب ئەپلیکەیشنی دیلۆس ئینتەرناشناڵ',
      // created_at: '2021-08-20T19:49:58Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Note-Taker',
      // homepage: 'https://mehdi-note-taker.herokuapp.com/',
      // description:
      //   'Note Taker can be used to write and save notes. This application will use an Express.js back end and will save and retrieve note data from a JSON file.',
      project_img: '../images/products/g4.jpg',
      languages: {
        JavaScript: 8983,
        HTML: 2553,
        CSS: 1323,
      },
    },
    {
      _id: '5',
      projectName: 'مێنوی رێستورانت',
      // created_at: '2021-08-15T10:34:24Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Team-profile-generator',
      // homepage: 'https://MehdiMahmud79.github.io/Team-profile-generator/',
      // description:
      //   'This app is Object-Oriented Programming to generate a Team profile and render it in to an Html file for a given team based on the user input. ',
      // project_img:
      //   'https://github.com/MehdiMahmud79/Team-profile-generator/blob/main/assets/screen.gif?raw=true',
      project_img: '../images/products/g5.jpg',
      languages: {
        JavaScript: 27688,
      },
    },
    {
      _id: '6',
      projectName: 'سیستەمی دایمۆند سێرڤیس',
      // created_at: '2021-08-13T11:02:48Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/ProfessionalPortfolio',
      // homepage: 'https://MehdiMahmud79.github.io/ProfessionalPortfolio/',
      // description:
      //   'This app is using the GitHub API to generate an updated portfolio form my projects on GitHub.',
      project_img: '../images/products/g6.jpg',
      languages: {
        CSS: 20177,
        HTML: 12243,
        JavaScript: 7715,
      },
    },
    {
      _id: '7',
      projectName: 'سیستەمی کۆمپانیای ڤینیسیا',
      // created_at: '2021-08-07T18:14:55Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Markup-Generator',
      // homepage: 'https://MehdiMahmud79.github.io/Markup-Generator/',
      // description:
      //   'A profesional markup generator to creat ReadMe file for github projects.',
      project_img: '../images/products/g7.jpg',
      languages: {
        JavaScript: 4490,
      },
    },
    {
      _id: '8',
      projectName: 'سیستەمی کۆمپانیای ڤینیسیا',
      // created_at: '2021-08-07T18:14:55Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Markup-Generator',
      // homepage: 'https://MehdiMahmud79.github.io/Markup-Generator/',
      // description:
      //   'A profesional markup generator to creat ReadMe file for github projects.',
      project_img: '../images/products/g8.jpg',
      languages: {
        JavaScript: 4490,
      },
    },
  ],

  lighProducts: [
    {
      _id: '1',
      projectName: 'سیستەمی ئاڵوگۆڕی دراو',
      // created_at: '2021-10-16T21:51:35Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Training-Tracker',
      // homepage: 'https://training-tracker-uk.herokuapp.com/',
      // description:
      //   'In this app you are able to view create and track daily workouts using MongoDb Atlas and deployed to Heroku. You also be able to log multiple exercises in a workout on a given day.',
      project_img: '../images/products/l1.jpg',
      languages: {
        JavaScript: 16404,
        CSS: 7166,
        HTML: 5742,
      },
    },
    {
      _id: '2',
      projectName: 'مێنو ساردەمەنی هەولێر',
      // created_at: '2021-09-02T11:02:31Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/E-Commerce-BackEnd',
      // homepage: 'https://MehdiMahmud79.github.io/E-Commerce-BackEnd/',
      // description:
      //   'An app to interact with a MySQL database Using Sequelize in ExpressJs and API.',
      project_img: '../images/products/l2.jpg',
      languages: {
        JavaScript: 17481,
        Shell: 41,
      },
    },
    {
      _id: '3',
      projectName: 'ماڵپەڕی کۆمپانیای سیپاک',
      // created_at: '2021-08-31T11:06:31Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Employee-Tracker',
      // homepage: 'https://MehdiMahmud79.github.io/Employee-Tracker/',
      // description:
      //   'Using the SQL databse to creat a command-line application interfaces that allow non-developers to easily view and interact with information stored in databases.',
      project_img: '../images/products/l3.jpg',
      languages: {
        JavaScript: 11283,
      },
    },
    {
      _id: '4',
      projectName: 'وێب ئەپلیکەیشنی دیلۆس ئینتەرناشناڵ',
      // created_at: '2021-08-20T19:49:58Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Note-Taker',
      // homepage: 'https://mehdi-note-taker.herokuapp.com/',
      // description:
      //   'Note Taker can be used to write and save notes. This application will use an Express.js back end and will save and retrieve note data from a JSON file.',
      project_img: '../images/products/l4.jpg',
      languages: {
        JavaScript: 8983,
        HTML: 2553,
        CSS: 1323,
      },
    },
    {
      _id: '5',
      projectName: 'مێنوی رێستورانت',
      // created_at: '2021-08-15T10:34:24Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Team-profile-generator',
      // homepage: 'https://MehdiMahmud79.github.io/Team-profile-generator/',
      // description:
      //   'This app is Object-Oriented Programming to generate a Team profile and render it in to an Html file for a given team based on the user input. ',
      // project_img:
      //   'https://github.com/MehdiMahmud79/Team-profile-generator/blob/main/assets/screen.gif?raw=true',
      project_img: '../images/products/l5.jpg',
      languages: {
        JavaScript: 27688,
      },
    },
    {
      _id: '6',
      projectName: 'سیستەمی دایمۆند سێرڤیس',
      // created_at: '2021-08-13T11:02:48Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/ProfessionalPortfolio',
      // homepage: 'https://MehdiMahmud79.github.io/ProfessionalPortfolio/',
      // description:
      //   'This app is using the GitHub API to generate an updated portfolio form my projects on GitHub.',
      project_img: '../images/products/l6.jpg',
      languages: {
        CSS: 20177,
        HTML: 12243,
        JavaScript: 7715,
      },
    },
    {
      _id: '7',
      projectName: 'سیستەمی کۆمپانیای ڤینیسیا',
      // created_at: '2021-08-07T18:14:55Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Markup-Generator',
      // homepage: 'https://MehdiMahmud79.github.io/Markup-Generator/',
      // description:
      //   'A profesional markup generator to creat ReadMe file for github projects.',
      project_img: '../images/products/l7.jpg',
      languages: {
        JavaScript: 4490,
      },
    },
    {
      _id: '8',
      projectName: 'سیستەمی کۆمپانیای ڤینیسیا',
      // created_at: '2021-08-07T18:14:55Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Markup-Generator',
      // homepage: 'https://MehdiMahmud79.github.io/Markup-Generator/',
      // description:
      //   'A profesional markup generator to creat ReadMe file for github projects.',
      project_img: '../images/products/l8.jpg',
      languages: {
        JavaScript: 4490,
      },
    },

  ],

  pipeProducts: [
    {
      _id: '1',
      projectName: 'سیستەمی ئاڵوگۆڕی دراو',
      // created_at: '2021-10-16T21:51:35Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Training-Tracker',
      // homepage: 'https://training-tracker-uk.herokuapp.com/',
      // description:
      //   'In this app you are able to view create and track daily workouts using MongoDb Atlas and deployed to Heroku. You also be able to log multiple exercises in a workout on a given day.',
      project_img: '../images/products/p1.jpg',
      languages: {
        JavaScript: 16404,
        CSS: 7166,
        HTML: 5742,
      },
    },
    {
      _id: '2',
      projectName: 'مێنو ساردەمەنی هەولێر',
      // created_at: '2021-09-02T11:02:31Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/E-Commerce-BackEnd',
      // homepage: 'https://MehdiMahmud79.github.io/E-Commerce-BackEnd/',
      // description:
      //   'An app to interact with a MySQL database Using Sequelize in ExpressJs and API.',
      project_img: '../images/products/p2.jpg',
      languages: {
        JavaScript: 17481,
        Shell: 41,
      },
    },
    {
      _id: '3',
      projectName: 'ماڵپەڕی کۆمپانیای سیپاک',
      // created_at: '2021-08-31T11:06:31Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Employee-Tracker',
      // homepage: 'https://MehdiMahmud79.github.io/Employee-Tracker/',
      // description:
      //   'Using the SQL databse to creat a command-line application interfaces that allow non-developers to easily view and interact with information stored in databases.',
      project_img: '../images/products/p3.jpg',
      languages: {
        JavaScript: 11283,
      },
    },
    {
      _id: '4',
      projectName: 'وێب ئەپلیکەیشنی دیلۆس ئینتەرناشناڵ',
      // created_at: '2021-08-20T19:49:58Z',
      // gitHub_Url: 'https://github.com/MehdiMahmud79/Note-Taker',
      // homepage: 'https://mehdi-note-taker.herokuapp.com/',
      // description:
      //   'Note Taker can be used to write and save notes. This application will use an Express.js back end and will save and retrieve note data from a JSON file.',
      project_img: '../images/products/p4.jpg',
      languages: {
        JavaScript: 8983,
        HTML: 2553,
        CSS: 1323,
      },
    },
   

  ],
};
